import React, { useState, useEffect } from "react";
import "../Pages/Home/Home.css";
import { IoClose } from "react-icons/io5";
import sport_placeholder from "../images/sport-placeholder.svg";
import { IoCalendarOutline } from "react-icons/io5";
import badminton from '../images/badminton.svg'
import football from '../images/football.svg'
import cricket from '../images/cricket.svg'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast, ToastContainer } from 'react-toastify';

const SportsTimePicker = ({ onSearch }) => {
  const [selectedSport, setSelectedSport] = useState("");
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selectedDateRaw, setSelectedDateRaw] = useState("");
  const [fromTime, setFromTime] = useState("");
  const [toTime, setToTime] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [sportsData, setSportsData] = useState([]);

  const timeSlotMap = {
    1: "1:00 AM", 2: "1:30 AM", 3: "2:00 AM", 4: "2:30 AM",
    5: "3:00 AM", 6: "3:30 AM", 7: "4:00 AM", 8: "4:30 AM",
    9: "5:00 AM", 10: "5:30 AM", 11: "6:00 AM", 12: "6:30 AM",
    13: "7:00 AM", 14: "7:30 AM", 15: "8:00 AM", 16: "8:30 AM",
    17: "9:00 AM", 18: "9:30 AM", 19: "10:00 AM", 20: "10:30 AM",
    21: "11:00 AM", 22: "11:30 AM", 23: "12:00 PM", 24: "12:30 PM",
    25: "1:00 PM", 26: "1:30 PM", 27: "2:00 PM", 28: "2:30 PM",
    29: "3:00 PM", 30: "3:30 PM", 31: "4:00 PM", 32: "4:30 PM",
    33: "5:00 PM", 34: "5:30 PM", 35: "6:00 PM", 36: "6:30 PM",
    37: "7:00 PM", 38: "7:30 PM", 39: "8:00 PM", 40: "8:30 PM",
    41: "9:00 PM", 42: "9:30 PM", 43: "10:00 PM", 44: "10:30 PM",
    45: "11:00 PM", 46: "11:30 PM", 47: "12:00 AM", 48: "12:30 AM"
  };

  const filterToTimeSlots = () => {
    if (!fromTime) return Object.values(timeSlotMap);
    const fromIndex = Object.values(timeSlotMap).indexOf(fromTime);
    return Object.values(timeSlotMap).slice(fromIndex + 1);
  };

  const convertTimeToSlotIndex = (time) => {
    return Object.keys(timeSlotMap).find(
      (key) => timeSlotMap[key] === time
    );
  };

  const formatSelectedTime = () => {
    if (!selectedDateRaw || !fromTime || !toTime) return "";
    const date = new Date(selectedDateRaw);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });

    const fromSlot = convertTimeToSlotIndex(fromTime);
    const toSlot = convertTimeToSlotIndex(toTime);

    const formattedDate = `${day}${getOrdinalSuffix(day)} ${month}`;
    return `${formattedDate} - ${fromTime} to ${toTime} (${(toSlot - fromSlot) * 0.5}Hr)`;
  };

  const getOrdinalSuffix = (day) => {
    if (day > 10 && day < 20) return "th";
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const handleSearch = async () => {
    if (!selectedSport) {
      toast.error("Please select a sport!");
      return;
    }
    if (!selectedTime) {
      toast.error("Please select a valid date and time!");
      return;
    }

    const fromSlot = convertTimeToSlotIndex(fromTime);
    const toSlot = convertTimeToSlotIndex(toTime);

    const requestBody = {
      city: "Mysuru",
      latitude: 12.352154945912245,
      longitude: 76.61426438740875,
      sport: selectedSport,
      date: selectedDateRaw, // Fixed the issue by using selectedDateRaw
      slots: [fromSlot, toSlot], // Send the slot indices instead of time strings
    };

    // Log the request body to the console

    try {
      const response = await fetch('http://93.127.206.96:8080/api/pg/filter', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();

      // Clear previous data and update sports data with the new response
      setSportsData(data);

      // Call the onSearch callback (if it exists) to pass the data to parent component
      if (onSearch) {
        onSearch(data);
      }
    } catch (error) {
      console.error('Error making API request:', error);
    }
  };

  useEffect(() => {
    if (selectedDateRaw && fromTime && toTime) {
      setSelectedTime(formatSelectedTime());
      setDropdownVisible(false);
    }
  }, [selectedDateRaw, fromTime, toTime]);
  

  return (
    <div className="sports-container">
      <div className="form-container">
        <div className="sports-dropdown" style={{ borderRight: "1px solid grey" }}>
          <label htmlFor="sports">Sports</label>
          <button
            className="transparent-button"
            onClick={() => setOverlayVisible(true)}
          >
            {selectedSport ? (
              selectedSport
            ) : (
              <div className="placeholder-content">
                <img
                  style={{ width: "7%", marginRight: "3%" }}
                  src={sport_placeholder}
                  alt="Sport Placeholder"
                  className="placeholder-image"
                />
                Choose Sport
              </div>
            )}
          </button>

          {overlayVisible && (
            <div className="overlay-sports">
              <div className="overlay-content-home">
                <div className="header-overlay">
                  <h2 id="h2-title-overlay">Select Sports</h2>
                  <button
                    className="close-btn"
                    onClick={() => setOverlayVisible(false)}
                  >
                    <IoClose />
                  </button>
                </div>
                <hr style={{ color: "var(--overlay-hr)" }} />
                <div className="tags-container">
                {[
                  { name: "Football", image: football },
                  { name: "Cricket", image: cricket },
                  { name: "Badminton", image: badminton },
                ].map((sport) => (
                  <div
                    key={sport.name}
                    className="tag"
                    onClick={() => {
                      setSelectedSport(sport.name);
                      setOverlayVisible(false);
                    }}
                  >
                    <img src={sport.image} alt={sport.name} className="sport-icon" />
                    <span>{sport.name}</span>
                  </div>
                ))}
              </div>

              </div>

            </div>
          )}
        </div>

        <div className="time-dropdown">
          <label htmlFor="time-button">When</label>
          <button
            id="time-button"
            className="time-button"
            onClick={() => setDropdownVisible(!dropdownVisible)}
          >
            <IoCalendarOutline style={{ marginRight: "8px", verticalAlign: "middle" }} />
            {selectedTime || "Select Date & Time"}
          </button>

          {dropdownVisible && (
            <div className="time-dropdown-window">
              <label htmlFor="date">Select Date</label>
              <DatePicker
                selected={selectedDateRaw}
                onChange={(date) => setSelectedDateRaw(date)}
                minDate={new Date()} // Prevent past dates
                placeholderText="Select Date" // Custom placeholder
                dateFormat="MM/dd/yyyy" // Custom date format
                className="form-control" // Bootstrap styling
              />
              <div>
                <label htmlFor="time-select" className="form-label">
                  Select Time
                </label>
                <div className="row">
                  <div className="col-md-6">
                    <select
                      id="from-time"
                      className="form-select"
                      value={fromTime}
                      onChange={(e) => setFromTime(e.target.value)}
                    >
                      <option value="">From :</option>
                      {Object.values(timeSlotMap).map((time) => (
                        <option key={time} value={time}>
                          {time}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-6">
                    <select
                      id="to-time"
                      className="form-select"
                      value={toTime}
                      onChange={(e) => setToTime(e.target.value)}
                    >
                      <option value="">To :</option>
                      {filterToTimeSlots().map((time) => (
                        <option key={time} value={time}>
                          {time}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              
            </div>
          )}



        </div>

        <button onClick={handleSearch} className="search-button">
          Search
        </button>

        <ToastContainer />

      </div>
    </div>
  );
};

export default SportsTimePicker;
