import axios from "axios";


export const loginUser = async (email, password) => {
  try {
    // Log the request data

    // Send a POST request with the correct data (email and password)
    const response = await axios.post("http://93.127.206.96:8080/api/auth/login", {
      username: email, 
      password: password,
    });

    // Log the successful response

    const token = response.data.token; 
    const userData = response.data; 

    if (token) {
      // Store the token and userData in sessionStorage
      sessionStorage.setItem('authToken', token);
      sessionStorage.setItem('userData', JSON.stringify(userData)); // Store user data as a JSON string
      sessionStorage.setItem('email',email)
    } else {
      console.log("No token found in response data");
    }

    return { success: true, message: "Login successful", data: response.data };
  } catch (error) {
    // Log the error response
    console.log("Login Error Response:", error.response?.data);
    return { success: false, message: error.response?.data?.message || "Login failed" };
  }
};



// Register function (email/password)
export const registerUser = async (email, password, name, phone) => {
  try {
    // Log the request data

    // Send a POST request for user registration
    const response = await fetch("http://93.127.206.96:8080/api/user/register/user", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        email,
        phone,
        password,
      }),
    });

    // Check if the response is JSON
    const contentType = response.headers.get("content-type");

    let data;
    if (contentType && contentType.includes("application/json")) {
      data = await response.json();
    } else {
      data = await response.text(); // Fallback to text if not JSON
    }

    // Log the response data

    // Return a success response if registration was successful
    return { success: true, data };
  } catch (error) {
    // Log the error response
    console.error("Registration Error Response:", error);
    return { success: false, message: "Registration failed" };
  }
};

// Register Arena Function (name, email, phone, password)
export const registerArena = async (email, password, name, phone) => {
  try {
    // Log the request data for arena registration

    // Send a POST request for arena registration (same as user registration)
    const response = await fetch("http://93.127.206.96:8080/api/user/register/pgadmin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        email,
        phone,
        password,
      }),
    });

    // Check if the response is JSON
    const contentType = response.headers.get("content-type");

    let data;
    if (contentType && contentType.includes("application/json")) {
      data = await response.json();
    } else {
      data = await response.text(); // Fallback to text if not JSON
    }

    // Log the response data

    // Return a success response if registration was successful
    return { success: true, data };
  } catch (error) {
    // Log the error response
    console.error("Register Arena Error Response:", error);
    return { success: false, message: "Arena registration failed" };
  }
};

export const handleGoogleLoginSuccess = async (response, navigate) => {
  const { credential } = response; // Capture the Google credential from the response

  try {
    // Make the POST request to your backend API
    const apiResponse = await axios.post("http://93.127.206.96:8080/api/auth/oauth/google", {
      token: credential, // Pass the credential from Google
      role: "User", // Specify the role as needed
    });

    if (apiResponse.data.success) {
      console.log("Google Sign-In Success:", apiResponse.data);

      // Store the token in session storage for authentication
      if (apiResponse.data.token) {
        sessionStorage.setItem("authToken", apiResponse.data.token);
        console.log("Token saved from Google Sign-In response:", apiResponse.data.token);
      }

      alert("Sign-In Successful!");
      navigate("/"); // Redirect to the home page or desired route
    } else {
      console.log("Google Sign-In failed:", apiResponse.data.message || "Please try again.");
      alert("Sign-In Failed. Please try again.");
    }
  } catch (error) {
    console.error("Google Sign-In Error:", error.response?.data || error.message);
    alert("An error occurred during Google Sign-In. Please try again.");
  }
};


// Reset Password Request (Initial step)
export const resetPassword = async (email) => {
  try {
    console.log('resetPassword section initiated')
    const response = await axios.post("http://93.127.206.96:8080/api/auth/password-reset", { email });
    if (response.data.token) {
      return { success: true, token: response.data.token };
    }
    return { success: false, message: response.data.message };
  } catch (error) {
    console.error("Password Reset Request Error:", error);
    return { success: false, message: "Failed to send reset link" };
  }
};

// Final Password Reset (Confirm with token)
export const resetPasswordWithToken = async (token, email, password) => {
  try {
    const response = await axios.post(`http://93.127.206.96:8080/api/auth/password-reset/${token}`, { email, password });
    return { success: true, message: "Password reset successful" };
  } catch (error) {
    console.error("Password Reset Error:", error);
    return { success: false, message: "Password reset failed" };
  }
};
