import React from 'react';
import Container from 'react-bootstrap/Container';
import './Login.css';
import Logo from '../../images/logo.svg'
import { Col } from 'react-bootstrap';
import ForgotPassword from './ForgotpasswordForm';

const ForgotPasswordPage = () => {
  return (
    <div className="login-bg">
      <Container fluid className="login-container h-50 p-0">
        {/* Left Side */}
        <Col>
            <div className="text-center p-4">
                <img className='image' src={Logo} alt='Sport'/>
            </div>
        </Col>
        {/* Right Side - Form Section */}
        <Col>
            <ForgotPassword/>
        </Col>
      </Container>
    </div>
  );
};

export default ForgotPasswordPage;
