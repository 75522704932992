import badminton from '../../images/badminton.svg';
import football from '../../images/football.svg';
import cricket from '../../images/cricket.svg';

// Homecontroller.js
const API_BASE_URL = "http://93.127.206.96:8080/api";

// Cache object to store data temporarily
const cache = {};

/**
 * Fetch PG details by ID.
 * Caches the result for future use.
 * @param {string} pgId - The ID of the PG to fetch data for.
 * @returns {Promise<object>} - The PG data.
 */
export const fetchPgDetails = async (pgId) => {
  if (cache[pgId]) {
    return cache[pgId];
  }

  try {
    // Retrieve the token from session storage
    const authToken = sessionStorage.getItem("authToken");
    const response = await fetch(`${API_BASE_URL}/pg/${pgId}`, {
      method: "GET", // GET method to fetch data
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken ? `Bearer ${authToken}` : "", // Include the token if it exists
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch data for PG ID: ${pgId}`);
    }

    const data = await response.json();
    cache[pgId] = data; // Store the result in cache
    return data;
  } catch (error) {
    console.error("Error fetching PG details:", error);
    throw error;
  }
};

/**
 * Fetch recommended PG locations nearby based on PG ID.
 * @param {string} pgId - The ID of the PG to fetch recommended locations.
 * @returns {Promise<Array<object>>} - The list of recommended locations.
 */
export const fetchRecommendedLocations = async (pgId) => {
  if (cache[pgId]) {
    return cache[pgId];
  }

  try {
    const response = await fetch(`${API_BASE_URL}/pg/nearby/${pgId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch recommended locations for PG ID: ${pgId}`);
    }

    const data = await response.json();
    cache[pgId] = data; // Cache the result for future use
    return data;
  } catch (error) {
    console.error("Error fetching recommended locations:", error);
    throw error;
  }
};

/**
 * Retrieve sports data from PG details.
 * @param {object} pgData - The full PG data object.
 * @returns {Array<object>} - Array of sports with names and optional icons.
 */
export const getSportsFromPgData = (pgData) => {
  if (!pgData.sportInfo || !Array.isArray(pgData.sportInfo)) {
    return [];
  }

  // Map sports to include icons if available
  const iconsMap = {
    Badminton: badminton,
    Football: football,
    Cricket: cricket,
  };

  return pgData.sportInfo.map((sport) => ({
    name: sport.sport,
    icon: iconsMap[sport.sport] || "default/icon/path.svg",
  }));
};
