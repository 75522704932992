import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

// Replace 'YOUR_GOOGLE_CLIENT_ID' with your actual Google Client ID
const googleClientId = '376732350063-9i343sgjqq2adae63rv7ctt0ksv1i5kd.apps.googleusercontent.com';

root.render(
    <GoogleOAuthProvider clientId={googleClientId}>
      <Router>
        <App />
      </Router>
    </GoogleOAuthProvider>
);

reportWebVitals();
