import React, { useState } from "react";
import "./Component.css";
import { FaClock } from "react-icons/fa6";
import { BsFillGrid1X2Fill } from "react-icons/bs";
import { FaClipboardList } from "react-icons/fa";

const ArenaInformation = () => {
  const [selectedInfo, setSelectedInfo] = useState(null);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);

  const handleOverlay = (info) => {
    setSelectedInfo(info);
    setIsOverlayVisible(true);
  };

  const closeOverlay = () => {
    setIsOverlayVisible(false);
    setSelectedInfo(null);
  };

  const infoDetails = {
    "Opening Hours": {
      content: "Opening hours information goes here.",
      icon: <FaClock />,
    },
    "Facilities": {
      content: "Facilities information goes here.",
      icon: <BsFillGrid1X2Fill />,
    },
    "Arena Policy": {
      content: `
        <b>Booking Refunds & Changes</b><br/>
        1. Strictly no refund or carry forward of non-utilised session.<br/>
        2. 48 hours advance notice must be given for any change of booking time. Any request to change booking time is subject to court availability.<br/>
        3. Strictly for social games only. Court bookings are not allowed to host mini tournaments/events, else Management has the right to deny entry.<br/>
        4. Management reserves the right to charge additional cleaning fee and no refund will be given if it is caught that court renting is for other purposes.<br/><br/>
    
        <b>Others</b><br/>
        1. Please do not leave your valuables unattended. We will not be responsible for any theft.
      `,
      icon: <FaClipboardList />,
    },
  };

  return (
    <div className="arena-info">
      <h2>Arena Information</h2>
      <div className="info-list">
        {Object.keys(infoDetails).map((key, index) => (
          <div
            key={index}
            className="info-item"
            onClick={() => handleOverlay(infoDetails[key].content)}
          >
            <span className="icon">{infoDetails[key].icon}</span>
            <span>{key}</span>
          </div>
        ))}
      </div>

      {isOverlayVisible && (
        <div className="overlay-arena-info">
        <div className="overlay-content-home">
          <div className="header">
            <h3>{Object.keys(infoDetails).find(
              (key) => infoDetails[key].content === selectedInfo
            )}</h3>
            <span className="close-icon" onClick={closeOverlay}>
              &times;
            </span>
            
          </div>
          <hr style={{margin:'5px'}}></hr>
          {/* Use dangerouslySetInnerHTML to render bold headings */}
          <p dangerouslySetInnerHTML={{ __html: selectedInfo }}></p>
        </div>
      </div>
      
      )}
    </div>
  );
};

export default ArenaInformation;
