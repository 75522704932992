import React, { useState } from "react";
import { resetPassword } from "../../Controllers/Auth_Controllers/AuthController"; 
import { useNavigate } from "react-router-dom"; // React Router v6
import "./Login.css"; // Reuse the same CSS for consistent design

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!email) {
      setError("Email is required");
      setLoading(false);
      return;
    }

    setError("");
    setSuccessMessage("");

    try {
      await resetPassword(email);
      setSuccessMessage("Password reset link sent to your email");
      navigate('/');
    } catch (error) {
      console.error("Error:", error);
      setError("An unexpected error occurred");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-form">
      <h1>Forgot Password</h1>

      <p id="sign-up-text">
        Remembered your password?{" "}
        <a href="/">
          Login
        </a>
      </p>

      <form onSubmit={handleForgotPassword}>
        <div className="input-field">
          <label>Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            required
          />
        </div>

        {error && <p className="error-message">{error}</p>}
        {successMessage && <p className="success-message">{successMessage}</p>}

        <button type="submit" disabled={loading} className="login-button">
          {loading ? "Sending..." : "Send Reset Link"}
        </button>
      </form>
    </div>
  );
};

export default ForgotPassword;
