import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Importing icons
import { loginUser, registerUser, registerArena, handleGoogleLoginSuccess, resetPassword } from "../../Controllers/Auth_Controllers/AuthController"; 
import { GoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom"; // React Router v6
import "./Login.css";

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [mode, setMode] = useState("login");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [arenaType, setArenaType] = useState("bookArena");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    if (!email) {
      setError("Email is required");
      setLoading(false);
      return;
    }
  
    if ((mode === "login" || mode === "signUp") && !password) {
      setError("Password is required");
      setLoading(false);
      return;
    }
  
    if (mode === "signUp" && (!name || !phone)) {
      setError("All fields are required for Sign Up");
      setLoading(false);
      return;
    }
  
    if (mode === "signUp" && !arenaType) {
      setError("Please select an arena option");
      setLoading(false);
      return;
    }
  
    setError("");
  
    try {
      if (mode === "login") {
        const response = await loginUser(email, password);
        if (!response.success) {
          setError(response.message);
        } else {
          // Store the token in session storage
          if (response.token) {
            sessionStorage.setItem('authToken', response.token);
            console.log('Token saved from login response:', response.token);
          }
          navigate("/");
        }
      } else if (mode === "forgotPassword") {
        await resetPassword(email);
        setError("Password reset link sent to your email");
      } else if (mode === "signUp") {
        let response;
        if (arenaType === "bookArena") {
          response = await registerUser(email, password, name, phone);
        } else if (arenaType === "registerArena") {
          response = await registerArena(email, password, name, phone);
        }
  
        if (!response.success) {
          setError(response.message);
        } else {
          navigate("/login");
        }
      }
    } catch (error) {
      console.error("Error:", error);
      setError("An unexpected error occurred");
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <div className="login-form-page">
      <h1>
        {mode === "login" ? "Login" : mode === "forgotPassword" ? "Forgot Password" : "Sign Up"}
      </h1>

      <p id="sign-up-text">
        {mode === "login" && (
          <>
            Don't have an account?{" "}
            <a href="#" onClick={() => {setMode("signUp");navigate('/login/signup')}}>
              Sign Up
            </a>
          </>
        )}
        {mode === "signUp" && (
          <>
            Already have an account?{" "}
            <a href="#" onClick={() => {setMode("login");navigate('/login')}}>
              Login
            </a>
          </>
        )}
        {mode === "forgotPassword" && (
          <>
            Remembered your password?{" "}
            <a href="#" onClick={() => setMode("login")}>
              Login
            </a>
          </>
        )}
      </p>

      {/* Arena selection for Sign Up */}
      {mode === "signUp" && (
        <div className="input-field">
        <div className="radio-group">
          <label className="radio-option">
            <input
              type="radio"
              value="bookArena"
              checked={arenaType === 'bookArena'}
              onChange={(e) => setArenaType(e.target.value)}
            />
            Book an Arena
          </label>
          <label className="radio-option">
            <input
              type="radio"
              value="registerArena"
              checked={arenaType === 'registerArena'}
              onChange={(e) => setArenaType(e.target.value)}
            />
            Register an Arena
          </label>
        </div>
      </div>
      
      
      )}

      <form onSubmit={handleSubmit}>
        {mode === "signUp" && (
          <>
            <div className="input-field">
              <label>Name</label>
              <input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Enter your name" required />
            </div>
            <div className="input-field">
              <label>Phone</label>
              <input type="text" value={phone} onChange={(e) => setPhone(e.target.value)} placeholder="Enter your phone number" required />
            </div>
          </>
        )}

        <div className="input-field">
          <label>Email</label>
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter your email" required />
        </div>

        {(mode === "login" || mode === "signUp") && (
         <div className="input-field">
         <label>Password</label>
         <div className="password-field">
           <input
             type={showPassword ? 'text' : 'password'}
             value={password}
             onChange={(e) => setPassword(e.target.value)}
             placeholder="Enter your password"
             required
           />
           <span className="password-icon" onClick={() => setShowPassword(!showPassword)}>
             {showPassword ? <FaEye /> : <FaEyeSlash />}
           </span>
         </div>
       </div>
        )}

        {error && <p className="error-message">{error}</p>}

        {mode === "login" && (
          <div className="checkbox-inline">
          <div className="left-section-login d-flex justify-content-between align-items-center">
            <div className="remember-me">
              <input type="checkbox" id="remember-me" />
              <label htmlFor="remember-me" style={{ marginLeft: '5px' }}>Remember me</label>
            </div>

          </div>
          <a 
              href="/forgot-password" 
              onClick={() => setMode("forgotPassword")} 
              className="forgot-password"
              style={{ marginLeft: 'auto' }}
            >
              Forgot Password?
            </a>
        </div>
        
        
        )}

        <button type="submit" disabled={loading} className="login-button">
          {loading ? "Loading..." : mode === "login" ? "Log In" : mode === "forgotPassword" ? "Send Reset Link" : "Sign Up"}
        </button>

        {mode !== "forgotPassword" && (
          <>
            <div className="divider">or</div>
            <GoogleLogin
              onSuccess={(response) => handleGoogleLoginSuccess(response)}
              onError={() => alert("Google Sign-In Error")}
            />
          </>
        )}
      </form>
    </div>
  );
};

export default LoginForm;
