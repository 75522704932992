import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./Home.css";
import NavbarComponent from "../../Components/Navbar";
import ArenaInformation from "../../Components/ArenaInformation";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";
import { fetchPgDetails, getSportsFromPgData,fetchRecommendedLocations } from "../../Controllers/Home/HomeController";
import { Row } from "react-bootstrap";
import SportsCard from "../../Components/CardComponent";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import coach from '../../images/coach.svg'
import response from '../../images/response.svg'
import environment from '../../images/environment.svg'

const CourtDetails = () => {
  const location = useLocation();
  const { sport } = location.state || {};
  const [imageUrls, setImageUrls] = useState([]);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const API_BASE_URL = "http://93.127.206.96:8080/api/files";
  const [selectedSport, setSelectedSport] = useState('Badminton'); // State for selected sport
  const [activities, setActivities] = useState([]);
  const [pgData, setPgData] = useState(null);
  const [recommendedLocations, setRecommendedLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const pgId = sport.id; // Assuming PG ID is available in the location state
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchPgDetails(pgId);
        setPgData(data);
        const sports = getSportsFromPgData(data);
        setActivities(sports);
      } catch (error) {
        console.error("Error fetching PG details:", error);
      }
    };

    const getLocations = async () => {
      try {
        const data = await fetchRecommendedLocations(pgId);
        setRecommendedLocations(data); // Set the recommended locations to state
        console.log('fetched recommended locations',data)
      } catch (error) {
        setError(error.message); // Set error if API call fails
      } finally {
        setLoading(false); // Set loading to false after the request is finished
      }
    };


    if (pgId) {
      fetchData();
      getLocations();

    }
  }, [pgId]);

  

  // Fetch image file names on component mount
  useEffect(() => {
    const fetchImageFileNames = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/list/66af2ef7125d801f88b4dc18`);
        if (!response.ok) {
          throw new Error("Failed to fetch image file names");
        }
        const fileNames = await response.json();

        const urls = fileNames.map(
          (fileName) => `${API_BASE_URL}/66af2ef7125d801f88b4dc18/${fileName}`
        );
        setImageUrls(urls);
      } catch (error) {
        console.error("Error fetching image data:", error);
      }
    };

    fetchImageFileNames();
  }, []);

  // Default marker icon for the map
  const defaultIcon = L.icon({
    iconUrl: markerIcon,
    shadowUrl: markerShadow,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
  });

  L.Marker.prototype.options.icon = defaultIcon;

  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
    setIsOverlayVisible(true); // Set overlay visibility to true when an image is clicked
  };

  const navigate = useNavigate();

 const handleBookNowClick = () => {
    
    if (selectedSport) {
      const selectedSportData = {
        name: sport.name,
        firstImage: imageUrls[0],
        address: `${sport.address.area}, ${sport.address.city}`,
        sport: selectedSport,
        pgid:sport.id
      };
      console.log('seleected sport details : ',selectedSportData);
      navigate("/booking-form", { state: selectedSportData });
    }
  };


  const closeOverlay = () => {
    setIsOverlayVisible(false); // Close the overlay when clicked
  };

  const handleTagClick = (activity) => {
    setSelectedSport(activity);
  };

  // Handle zoom control visibility when overlay is open
  useEffect(() => {
    const zoomControl = document.querySelector(".leaflet-control-zoom");
    if (zoomControl) {
      zoomControl.style.display = isOverlayVisible ? "none" : "block";
    }
  }, [isOverlayVisible]);

  if (!sport) {
    return <h1>Sport details not found</h1>;
  }

  const {
    name,
    address,
    location: { latitude, longitude },

    facilities = [
      { text: "Good Coaching", icon: coach },
      { text: "Quick Response", icon: response },
      { text: "Clean Environment", icon: environment },
    ]
    
  } = sport;

  return (
    <div>
      <NavbarComponent />
      <div className="court-details">
        <div className="court-header">
          <h1>{name}</h1>
          <p>{`${address.area}, ${address.city}`}</p>
        </div>
        <div className="court-body">
          <div className="court-images">
            <div className="left-image-section">
              {imageUrls[0] ? (
                <img
                  src={imageUrls[0]}
                  alt="Full court view"
                  loading="lazy"
                  onClick={() => handleImageClick(0)} // Handle click for opening overlay
                />
              ) : (
                <p>Loading image...</p>
              )}
            </div>
            <div className="right-grid-section">
              {imageUrls.slice(1, 5).map((url, index) => (
                <img
                  key={index}
                  src={url}
                  alt={`Court ${index + 2}`}
                  loading="lazy"
                  onClick={() => handleImageClick(index + 1)} // Handle click for opening overlay
                />
              ))}
              {imageUrls.length > 5 && (
                <div className="more-images-overlay">
                  <img
                    src={imageUrls[5]}
                    alt="More courts"
                    loading="lazy"
                    onClick={() => handleImageClick(5)} // Handle click for opening overlay
                  />
                  <div className="overlay">
                    <span>More Photos</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="court-details-container">
            <div className="court-info">
              <div className="section select-sports">
                <h2>Select Sports</h2>
                <div className="tags-container">
                  {activities.length > 0 ? (
                    activities.map((activity, index) => (
                      <span
                        key={index}
                        className={`tag ${
                          selectedSport === activity.name ? "selected" : ""
                        }`}
                        onClick={() => setSelectedSport(activity.name)}
                      >
                        <img
                          src={activity.icon}
                          alt={`${activity.name} icon`}
                          className="tag-icon"
                        />
                        {activity.name}
                      </span>
                    ))
                  ) : (
                    <p>No activities available</p>
                  )}
                </div>

              </div>
              <div className="section facilities">
                <h2>Top Features</h2>
                <div className="facilities-tags">
                  {facilities.length > 0 ? (
                    facilities.map((facility, index) => (
                      <span key={index} className="facility-tag">
                        <img
                          src={facility.icon}
                          alt={facility.text}
                          className="facility-icon"
                        />
                        {facility.text}
                      </span>
                    ))
                  ) : (
                    <p>No facilities listed</p>
                  )}
                </div>
              </div>

              <div className="section opening-hours">
                <ArenaInformation />
              </div>
            </div>

            {/* right section*/}
            <div className="court-map">
              {/* Book Now Button */}
              <div className="book-now-button">
                <button className="btn btn-primary w-100" onClick={handleBookNowClick}>
                  Book Now
                </button>
              </div>

              {/* Location Map */}
              <div
                className="map-container"
                onClick={() => {
                  window.open(
                    `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`,
                    "_blank"
                  );
                }}
              >
                <h2>Directions</h2>
                <p>{`${address.area}, ${address.city}`}</p>

                <MapContainer
                  center={[latitude, longitude]}
                  zoom={15}
                  style={{ height: "250px", width: "100%", borderRadius: "8px" }}
                >
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  />
                  <Marker position={[latitude, longitude]}>
                    <Popup>View on Google Maps</Popup>
                  </Marker>
                </MapContainer>

                {/* Text below the map */}
                <div style={{ textAlign: 'left', marginTop: '10px' }}>
                  <LocationOnIcon style={{color:'var(--primary-color)'}}/>
                  <a 
                    href={`https://www.google.com/maps?q=${latitude},${longitude}`} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none',color:'black',margin:'0 10px' }}
                  >
                    View in Google Maps
                  </a>
                </div>

              </div>
            </div>
          </div>

          {/* Recommended Section */}
          <div className="recommended">
            <h1>Recommended</h1>
            <Row className="card-grid">
            {recommendedLocations.length > 0 ? (
              recommendedLocations.map((sport) => (
                <SportsCard 
                  key={sport.id} 
                  sport={sport} 
                  userLocation={sport.userLocation} 
                />
              ))
            ) : (
              <p>No recommended locations found.</p>
            )}
          </Row>
          </div>
        </div>
      </div>

      {/* Overlay for Image Viewing */}
      {isOverlayVisible && (
  <div className={`sports-container ${isOverlayVisible ? 'visible' : ''}`}>
    <div className="overlay-sports">
      {/* Carousel */}
      <div
        id="carouselExampleControls"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        {/* Carousel items */}
        <div className="carousel-inner">
          {imageUrls.map((url, index) => (
            <div
              className={`carousel-item ${
                index === currentImageIndex ? 'active' : ''
              }`}
              key={index}
            >
              {/* Image with close button */}
              <div className="carousel-image-container position-relative">
                <img
                  src={url}
                  alt={`Court ${index + 1}`}
                  className="img-fluid d-block w-100"
                />
                <button
                  className="btn-close position-absolute top-0 end-0 m-2"
                  onClick={closeOverlay}
                  aria-label="Close"
                  style={{
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    borderRadius: '50%',
                    zIndex: 1050,
                  }}
                ></button>
              </div>
            </div>
          ))}
        </div>

        {/* Next and Prev controls */}
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="prev"
          onClick={() =>
            setCurrentImageIndex(
              (prevIndex) => (prevIndex - 1 + imageUrls.length) % imageUrls.length
            )
          }
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="next"
          onClick={() =>
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageUrls.length)
          }
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  </div>
)}

    </div>
  );
};

export default CourtDetails;
