import React, { useState, useEffect } from "react";
import { FaStar } from "react-icons/fa";
import "../Pages/Home/Home.css";
import { useNavigate } from "react-router-dom";

// Haversine formula to calculate distance between two points (in km)
const haversineDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371; // Radius of Earth in km
  const dLat = (lat2 - lat1) * (Math.PI / 180);
  const dLon = (lon2 - lon1) * (Math.PI / 180);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * (Math.PI / 180)) *
      Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c; // Distance in km
};

const SportsCard = ({ sport, userLocation }) => {
  const navigate = useNavigate();
  const { name, address, location: sportLocation, googleReview, activities } = sport;
  const { latitude: sportLat, longitude: sportLon } = sportLocation;

  const [imageSrc, setImageSrc] = useState("");
  const [distance, setDistance] = useState(null);

  useEffect(() => {
    const imageUrl = `http://93.127.206.96:8080/api/files/66af2ef7125d801f88b4dc18/cover1.jpg`;

    // Fetch the image
    fetch(imageUrl)
      .then((response) => {
        if (response.ok) {
          return response.blob();
        }
        throw new Error("Failed to load image");
      })
      .then((blob) => {
        setImageSrc(URL.createObjectURL(blob)); // Convert blob to object URL
      })
      .catch(() => {
        setImageSrc("fallback-image-url.jpg"); // Fallback image
      });

    // Calculate distance if userLocation is available
    if (userLocation && userLocation.latitude && userLocation.longitude) {
      const userLat = userLocation.latitude;
      const userLon = userLocation.longitude;

      const dist = haversineDistance(userLat, userLon, sportLat, sportLon);
      setDistance(dist.toFixed(2)); // Set the distance in km (rounded to 2 decimal places)
    } else {
      setDistance("Fetching location..."); // Default message when location is unavailable
    }
  }, [userLocation, sportLat, sportLon]);


  const handleBookClick = () => {
    console.log('sport in court',sport.id)
    navigate("/court", { state: { sport, imageSrc, id: sport.id,userLocation } }); // Pass the id
  };
  

  return (
    <div className="card">
      {/* Image Section */}
      <div className="card-image-container">
        <img src={imageSrc} alt={name} className="card-image" loading="lazy" />

        {/* Sport Tag */}
        {activities && (
          <div className="card-tag">
            {activities.join(", ")} {/* Assuming `activities` is an array */}
          </div>
        )}

        {/* Rating */}
        {googleReview && (
          <div className="card-rating">
            <FaStar color="#FFD700" size={14} /> {googleReview.rating || "N/A"}
          </div>
        )}
      </div>

      {/* Content Section */}
      <div className="card-content">
        <div className="card-left">
          <h3 className="card-title">{name}</h3>
          <p className="card-description">
            {address.area}, {address.city}
          </p>
          <p className="card-distance">
            {distance ? `${distance} km` : "Calculating distance..."}
          </p>
        </div>

        <div className="card-right">
          <button className="card-button" onClick={handleBookClick}>Book</button>
        </div>
      </div>
    </div>
  );
};

export default SportsCard;
