import React, { useEffect, useState } from "react";
import "./Component.css";
import BookingDetails from "../Pages/Payment/detailsComponent";

const BookingCard = ({ pgId, heading, date, fromTime, toTime, sport, courts, booking, onCancel,showHrLine }) => {
  const [address, setAddress] = useState("");
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);

  useEffect(() => {
    // Fetch 'sportsData' from localStorage
    const sportData = JSON.parse(localStorage.getItem("sportsData")) || [];
    const arena = sportData.find((item) => item.id === pgId);

    if (arena && arena.address) {
      const fullAddress = `${arena.address.street}, ${arena.address.area}, ${arena.address.city}, ${arena.address.state} - ${arena.address.pincode}`;
      setAddress(fullAddress);
    } else {
      console.warn("Arena not found for the given id:", pgId);
    }
  }, [pgId]);

  // Directly compute cancellation status
  const isCancelled = booking?.bookingStatus === "Cancelled";

  const handleCancelClick = () => {
    setIsOverlayVisible(true);
  };

  const handleOverlayCancel = () => {
    setIsOverlayVisible(false);
    onCancel(booking); // Trigger the cancel callback
  };

  const handleOverlayClose = () => {
    setIsOverlayVisible(false);
  };

  return (
    <>
      <div className={`booking-card ${isCancelled ? "cancelled" : ""}`}>
        {/* Header Section */}
        <div className="booking-card-header">
          <h2 className="booking-card-heading">{heading}</h2>
          <span className="booking-card-date">{date}</span>
        </div>

        {/* Body Section */}
        <div className="booking-card-body">
          <div className="booking-card-info">
            <p className="booking-card-address">{address}</p>
            <p className="booking-card-time">
              {fromTime} to {toTime}
            </p>
          </div>
          <div className="booking-card-details">
            <p className="booking-card-sport">{sport}</p>
            <p className="booking-card-courts">{courts.join(", ")}</p>
          </div>
        </div>

        {/* Footer Section */}
        <div className="booking-card-footer">
          {isCancelled ? (
            <button className="booking-card-cancelled-btn" disabled>
              Cancelled
            </button>
          ) : (
            <>
              <button className="booking-card-btn booking-card-invoice-btn">Get Invoice</button>
              <button
                className="booking-card-btn booking-card-cancel-btn"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
              <button className="booking-card-btn booking-card-location-btn">Get Location</button>
            </>
          )}
        </div>
      </div>

      {/* Overlay Component */}
      {isOverlayVisible && (
        <div className="booking-page-overlay">
          <div className="booking-page-overlay-content">
            <h3>Cancel Booking?</h3>
            {showHrLine && <hr />} {/* Conditional horizontal line */}
            <BookingDetails
              sport={sport}
              name={heading}
              address={address}
              selectedDate={date}
              fromTime={fromTime}
              toTime={toTime}
              selectedCourts={courts.map((court) => ({ court, cost: booking.baseAmount }))} // Mock costs
              timeSlotMap={{}}
              customStyle={{width:'100%',padding:'0'}}
              showHrLine={true}  // Enable conditional HR line
            />
            <p id="refund-text">You will be charged upto 50% of the payment for processing fee and the refund would take upto 2 working days. </p>
            <div className="overlay-actions">
              <button className="back-btn" onClick={handleOverlayClose}>
                Back
              </button>
              <button className="cancel-btn" onClick={handleOverlayCancel}>
                Cancel Booking
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BookingCard;
