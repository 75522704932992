// RightSection.js

import React from 'react';
import { SlCalender } from 'react-icons/sl';
import { CiClock1 } from 'react-icons/ci';
import { GiTennisCourt } from 'react-icons/gi';
import '../Pages/BookingDetails/bookingDetails.css'
const RightSection = ({ sport, name, address, firstImage, selectedDate, fromTime, toTime, timeSlotMap, selectedCourts, handlePayment, loading,customstyle }) => {
  const totalAmount = selectedCourts.reduce((acc, court) => acc + court.cost, 0) + selectedCourts.length * 25;

  return (
    <div className="right-section">
      <div className="sport-summary">
        {/* Display Sport, Arena, and Address */}
        <div className="sport-selected">
          <div className="sport-text">
            <p>{sport}</p>
            <h4>{name}</h4>
            <h5>{address}</h5>
          </div>
          <img src={firstImage} width="100px" alt="Sport" />
        </div>

        <hr />

        <div className="booking-details">
          <h5>Booking Details</h5>
          <p><SlCalender className="booking-icons" style={{marginRight:'20px'}} />{selectedDate}</p>
          <p><CiClock1 className="booking-icons" style={{marginRight:'20px'}} />{timeSlotMap[fromTime]} - {timeSlotMap[toTime]}</p>
          <p><GiTennisCourt className="booking-icons" style={{marginRight:'20px'}} />{selectedCourts.map((court) => `Court ${court.court}`).join(', ')}</p>
        </div>  

        <hr />

        <div className="price-summary">
          <h4>Price</h4>
          <div className="price-row">
            <p>Court:</p>
            <p>₹{selectedCourts.reduce((acc, court) => acc + court.cost, 0)}</p>
          </div>
          <div className="price-row">
            <p>Fee:</p>
            <p>₹{selectedCourts.length * 25 /* Fixed fee per court */}</p>
          </div>
          <div className="price-row">
            <p style={{color:'black',fontSize:'20px',fontWeight:'550',lineHeight:'20px'}}>Total:</p>
            <p id="totalAmt">₹{totalAmount}</p>
          </div>

          {totalAmount > 0 && (
            <div className="checkout-button-container">
              <button
                className="btn btn-primary checkout-button"
                onClick={handlePayment}
                disabled={loading}
              >
                {loading ? "Processing..." : "Proceed to Checkout"}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RightSection;
