  import React, { useState } from "react";
  import { useLocation,useNavigate } from "react-router-dom";
  import NavbarComponent from "../../Components/Navbar";
  import "./bookingDetails.css";
  import { useRazorpay } from "react-razorpay";
  import RightSection from "../../Components/BookingDetailsComponent";
  import { toast, ToastContainer } from 'react-toastify';
  import DatePicker from "react-datepicker";
  import "react-datepicker/dist/react-datepicker.css"; // Import CSS for DatePicker
  

  const BookingForm = () => {
    const { state } = useLocation(); // Access the passed state (selectedSportData)
    
    const [selectedDate, setSelectedDate] = useState("");
    const [fromTime, setFromTime] = useState("");
    const [toTime, setToTime] = useState("");
    const [courts, setCourts] = useState(null); 
    const [selectedCourts, setSelectedCourts] = useState([]); // Store selected courts details
    const [loading, setLoading] = useState(false); // Loading state for payment API
    const navigate = useNavigate();
    const userData = sessionStorage.getItem('userData')
    ? JSON.parse(sessionStorage.getItem('userData'))
    : null;
  
    const email = sessionStorage.getItem('email') || '';
    
    // Destructure sport data from state
    const { name, sport, firstImage, address } = state || {};
    const { Razorpay } = useRazorpay();
    
    // Safely access userId with a fallback
    const userId = userData?.userId || "123";
  

    const timeSlotMap = {
      1: "1:00 AM", 2: "1:30 AM", 3: "2:00 AM", 4: "2:30 AM",
      5: "3:00 AM", 6: "3:30 AM", 7: "4:00 AM", 8: "4:30 AM",
      9: "5:00 AM", 10: "5:30 AM", 11: "6:00 AM", 12: "6:30 AM",
      13: "7:00 AM", 14: "7:30 AM", 15: "8:00 AM", 16: "8:30 AM",
      17: "9:00 AM", 18: "9:30 AM", 19: "10:00 AM", 20: "10:30 AM",
      21: "11:00 AM", 22: "11:30 AM", 23: "12:00 PM", 24: "12:30 PM",
      25: "1:00 PM", 26: "1:30 PM", 27: "2:00 PM", 28: "2:30 PM",
      29: "3:00 PM", 30: "3:30 PM", 31: "4:00 PM", 32: "4:30 PM",
      33: "5:00 PM", 34: "5:30 PM", 35: "6:00 PM", 36: "6:30 PM",
      37: "7:00 PM", 38: "7:30 PM", 39: "8:00 PM", 40: "8:30 PM",
      41: "9:00 PM", 42: "9:30 PM", 43: "10:00 PM", 44: "10:30 PM",
      45: "11:00 PM", 46: "11:30 PM", 47: "12:00 AM", 48: "12:30 AM",
    };

    
    
    const handleApiRequest = async () => {
      if (!selectedDate || !fromTime || !toTime) {
        toast.success("Please fill in all fields before booking.");
        return;
      }
    
      const fromSlot = parseInt(fromTime, 10);
      const toSlot = parseInt(toTime, 10);

      const slotIds = [];
      for (let i = fromSlot; i < toSlot; i++) {
        slotIds.push(i);
      }

    
      if (fromSlot >= toSlot) {
        toast.error("'From Time' must be before 'To Time'.");
        return;
      }


    
      const apiUrl = `http://93.127.206.96:8080/api/book/slots`;
      const params = new URLSearchParams({
        sport: state.sport,
        slotIds: slotIds,
        userId: userId || "",
        bookingType: "DailyBooking",
        endDate: "",
        pgId: state.pgid,
        startDate: selectedDate,

      });
      


      try {
        const response = await fetch(`${apiUrl}?${params.toString()}`, {
          method: "GET",
        });
    
        
        const data = await response.json();
    
        if (data.length === 0) {
          setCourts([]);
          toast.error("No courts available for the selected time slot.");
          return;
        }
    
        // Add "selected" flag for unavailable courts
        const processedData = data.map((court) => ({
          ...court,
          selected: !court.available, // Mark as selected if not available
        }));
    
        setCourts(processedData);
        toast.success("Courts fetched successfully. Please select one.");
      } catch (error) {
        console.error("Error occurred during API request:", error);
        toast.error("Failed to fetch courts. Please try again.");
      }
    };
    
    

    const handleCourtSelect = (court) => {
      const isSelected = selectedCourts.some((selectedCourt) => selectedCourt.court === court.court);
      
      if (isSelected) {
        // Deselect court
        setSelectedCourts(selectedCourts.filter((selectedCourt) => selectedCourt.court !== court.court));
      } else {
        // Select court
        setSelectedCourts([...selectedCourts, court]);
      }
    };

    const pgId = state.pgid;

    const handlePayment = async () => {

      if (!selectedDate || !fromTime || !toTime) {
        toast.success("Please fill in all fields before booking.");
        return;
      }
    
      const fromSlot = parseInt(fromTime, 10);
      const toSlot = parseInt(toTime, 10);

      const slotIds = [];
      for (let i = fromSlot; i < toSlot; i++) {
        slotIds.push(i);
      }

    
      if (fromSlot >= toSlot) {
        toast.error("'From Time' must be before 'To Time'.");
        return;
      }

      
      // Retrieve the auth token from local storage
      const authToken = sessionStorage.getItem("authToken");
    
    
      if (selectedCourts.length === 0) {
        toast.error("Please select at least one court to proceed.");
        return;
      }
    
      // Prepare the data for the API call
      const courtIds = selectedCourts.map(court => court.court); // Extract court IDs
      const bookingData = {
        pgId: state.pgid,
        sport: state.sport,
        courtIds: courtIds,
        slotIds: slotIds,
        startDate: selectedDate,
        endDate: selectedDate, // Adjust as needed
        userId: userId || "", // Provide the userId if available
        pgName: state.name,
        bookingType: "CustomBooking",
      };
    
      setLoading(true);
    
      try {
        // First API call to book slots
        const bookSlotsResponse = await fetch("http://93.127.206.96:8080/api/book/slots", {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bookingData),
        });
    
        if (!bookSlotsResponse.ok) {
          throw new Error("Failed to book slots. Please try again.");
        }
    
        const bookSlotsData = await bookSlotsResponse.json();
    
        if (!authToken) {
          toast.error("Please sign in or login to proceed.");
          setLoading(false);
          return;
        }
    
        // Calculate total amount
        const totalAmount =
          selectedCourts.reduce((acc, court) => acc + court.cost, 0) +
          selectedCourts.length * 25;
    
        // Second API call to create the payment order
        const paymentApiUrl = `http://93.127.206.96:8080/api/payment/createOrder?amount=${totalAmount}&currency=INR`;
    
        const paymentResponse = await fetch(paymentApiUrl, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        });
    
        if (paymentResponse.status === 403) {
          toast.error("Please sign in or login to proceed.");
          setLoading(false);
          return;
        }
    
        const paymentData = await paymentResponse.json();
    
        if (!paymentResponse.ok || !paymentData?.id) {
          throw new Error("Failed to create payment order.");
        }
    
        // Razorpay options
        const options = {
          key: "rzp_test_6cHsJwMnXU4jUQ", // Replace with your Razorpay key
          amount: totalAmount, // Amount in paise
          currency: "INR",
          name: "SportSea",
          description: "Court Booking Payment",
          image: "https://example.com/your_logo",
          order_id: paymentData.id, 
          handler: function (paymentResponse) {
            toast.success("Payment successful!");
    
            navigate("/success", {
              state: {
                paymentResponse,
                sport,
                name,
                address,
                firstImage,
                selectedCourts,
                selectedDate,
                fromTime,
                slotIds,
                toTime,
                timeSlotMap,
                pgId,
              },
            });
          },
          prefill: {
            name: userData.name, // Replace with actual user data
            email: email, // Replace with actual user data
            contact: "9999999999", // Replace with actual user data
          },
          notes: {
            address: "Court Address",
          },
          theme: {
            color: "#3399cc",
          },
        };
    
        const rzp = new Razorpay(options);
    
        rzp.on("payment.failed", async function (response) {
          try {
            await cancelSlots(authToken, bookingData);
          } catch (error) {
            console.error("Error during slot cancellation:", error);
          }
          navigate("/failure", {
            state: {
              sport,
              name,
              address,
              firstImage,
              selectedCourts,
              selectedDate,
              fromTime,
              slotIds,
              toTime,
              timeSlotMap,
              pgId,
            },
          });
        });
        
        rzp.on("payment.canceled", async function (response) {
          try {
            await cancelSlots(authToken, bookingData);
          } catch (error) {
            console.error("Error during slot cancellation:", error);
          }
          navigate("/failure", {
            state: {
              sport,
              name,
              address,
              firstImage,
              selectedCourts,
              selectedDate,
              fromTime,
              toTime,
              timeSlotMap,
              pgId,
            },
          });
        });
        
    
        rzp.open();
      } catch (error) {
        console.error("Error occurred during payment initialization:", error);
        toast.error("Payment initialization failed. Please try again.");
      } finally {
        setLoading(false);
      }
    };
    
    // Function to cancel slots if payment fails or is canceled
    const cancelSlots = async (authToken, bookingData) => {
      try {
        const cancelResponse = await fetch("http://93.127.206.96:8080/api/cancel/slots", {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bookingData),
        });
    
        if (!cancelResponse.ok) {
          throw new Error("Failed to cancel slots.");
        }
    
        // Log success if the cancellation was successful
        const cancelData = await cancelResponse.json(); // If needed, you can log the response data
      } catch (error) {
        console.error("Error occurred during slot cancellation:", error);
      }
    };
    
    
  

    return (
      <div>
        <NavbarComponent />
        <div className="booking-page-container">
        <div className="left-section">
          <h1>{state.name}</h1>
          <p>{state.address}</p>
          <form className="booking-form">
            <div className="step-container">
              <div className="step-line">
                <div className="step-circle">1</div>
              </div>
              <div className="form-content-date">
              <label htmlFor="unique-date-input">Select Date:</label>
                <div className="unique-date-wrapper">
                  <input
                    type="date"
                    id="unique-date-input"
                    className={`unique-date-input ${selectedDate ? "date-filled" : ""}`}
                    value={selectedDate}
                    onChange={(e) => setSelectedDate(e.target.value)}
                    min={new Date().toISOString().split("T")[0]} // Restrict to today and future dates
                    required
                  />
                  {!selectedDate && (
                    <span className="unique-placeholder-text">Select Date</span>
                  )}
                </div>
              </div>

            </div>

            <div className="step-container">
              <div className="step-line">
                <div className="step-circle">2</div>
              </div>
              <div className="form-content">
                <div className="time-group">
                  <label htmlFor="fromTime">From Time:</label>
                  <select
                    id="fromTime"
                    className="form-control"
                    value={fromTime}
                    onChange={(e) => setFromTime(e.target.value)}
                    required
                  >
                    <option value="">Select From Time</option>
                    {Object.entries(timeSlotMap).map(([key, value]) => (
                      <option key={key} value={key}>
                        {value}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="time-group">
                  <label htmlFor="toTime">To Time:</label>
                  <select
                    id="toTime"
                    className="form-control"
                    value={toTime}
                    onChange={(e) => setToTime(e.target.value)}
                    required
                  >
                    <option value="">Select To Time</option>
                    {Object.entries(timeSlotMap).map(([key, value]) => (
                      <option key={key} value={key}>
                        {value}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="step-container">
              <div className="step-line-last">
                <div className="step-circle">3</div>
              </div>
              <div className="form-content-search">
                {courts === null ? (
                  // Show Search Court Button
                  <div className="form-group">
                    <label htmlFor="toTime">Select Court:</label>
                    <button
                      type="button"
                      className="btn btn-primary w-100"
                      onClick={handleApiRequest}
                    >
                      Search Court
                    </button>
                  </div>
                ) : (
                  // Display Court List
                  <div className="court-list">
                    {courts.length === 0 ? (
                      <p>No courts available for the selected time and date.</p>
                    ) : (
                      courts.map((court, index) => (
                        <div
                          key={index}
                          className={`court-card ${court.available ? '' : 'unavailable'} ${
                            selectedCourts.some((selectedCourt) => selectedCourt.court === court.court)
                              ? 'selected'
                              : ''
                          }`}
                          onClick={() => court.available && handleCourtSelect(court)} // Only selectable if available
                        >
                          <img
                            src={court.image || 'default-court.jpg'}
                            alt={`Court ${court.court}`}
                            className="court-image"
                          />
                          <div className="court-details-slot">
                            <div className="court-info">
                              <h3>{`Court ${court.court}`}</h3>
                              <p>{court.type || 'Wooden Court'}</p>
                            </div>
                            <div className="court-price">
                              {court.available ? (
                                <p className="price">₹{court.cost}</p>
                              ) : (
                                <p className="not-available">Not available</p>
                              )}
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                  </div>

                )}
              </div>
            </div>
            <ToastContainer />
 
          </form>
        </div>


          {/* Right Section */}
          <RightSection sport={sport} name={name} address={address} firstImage={firstImage} selectedCourts={selectedCourts} selectedDate={selectedDate} fromTime={fromTime} toTime={toTime} timeSlotMap={timeSlotMap} handlePayment={handlePayment} loading={loading}/>
        
        </div>
      </div>
    );
  };

  export default BookingForm;
