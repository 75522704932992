import React from 'react'
import NavbarComponent from '../../Components/Navbar'
import { Container,Row } from 'react-bootstrap'
import failure from '../../images/failure.svg'
import './payment.css'
import { useLocation } from "react-router-dom";
import DetailComponent from './detailsComponent'

const Failure = () => {
    const location = useLocation();
    const { state } = location; 
    const { sport, name, address, firstImage, selectedCourts, selectedDate, fromTime, toTime, timeSlotMap } = state || {};
  

  return (
    <div>
        <NavbarComponent />

        <Container>
            <Row className='topRow center'>
                <img id='successImg' src={failure} alt='success'/>
            </Row>
            <Row className='center midRow'>
                <h2>Booking Error</h2>
                <p>We have not received your payment.</p>
            </Row>
            <Row className='center endRow'> 
                <DetailComponent
                    sport={sport} 
                    name={name} 
                    address={address} 
                    firstImage={firstImage} 
                    selectedCourts={selectedCourts} 
                    selectedDate={selectedDate} 
                    fromTime={fromTime} 
                    toTime={toTime} 
                    timeSlotMap={timeSlotMap}
                    button1={false}
                    button2={false}
                    button3={true}
                />
            </Row>    
      </Container>
    </div>
  )
}

export default Failure
