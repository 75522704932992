import React, { useEffect, useState } from "react";
import NavbarComponent from "../../Components/Navbar";
import { Container, Row } from "react-bootstrap";
import success from "../../images/success.svg";
import "./payment.css";
import { useLocation } from "react-router-dom";
import DetailComponent from "./detailsComponent";

const Success = () => {
  const location = useLocation();
  const { state } = location;

  // Destructure data passed from the previous page
  const {
    sport,
    name,
    address,
    firstImage,
    selectedCourts,
    selectedDate,
    fromTime,
    toTime,
    slotIds,
    timeSlotMap,
    paymentResponse,
    pgId,
  } = state || {};

  // Get user data from session storage
  const email = sessionStorage.getItem("email");
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const userId = userData ? userData.userId : null;

  // State to track if the API call has been made
  const [orderCreated, setOrderCreated] = useState(false);

  // Set advance and balance to 0 as per your request
  const advance = 0;
  const balance = 0;

  // Calculate total amount from selected courts and their costs
  const totalAmount =
    selectedCourts.reduce((acc, court) => acc + court.cost, 0) +
    selectedCourts.length * 250; // Adjust total cost

  useEffect(() => {
    const createOrder = async () => {
      // Prevent duplicate API calls
      if (orderCreated) return;

      // Destructure the paymentResponse for the Razorpay details
      const orderData = {
        advance: 0,
        balance: 0,
        baseAmount: totalAmount,
        bookingType: "DailyBooking",
        courtIds: selectedCourts.map((court) => court.court),
        endDate: selectedDate,
        pgId: pgId,
        razorpayOrderId: paymentResponse.razorpay_order_id,
        razorpayPaymentId: paymentResponse.razorpay_payment_id,
        razorpaySignature: paymentResponse.razorpay_signature,
        slotIds: slotIds,
        sport: sport,
        startDate: selectedDate,
        totalAmount: totalAmount,
        userId: userId,
      };

      console.log(orderData)

      try {
        const response = await fetch(
          "http://93.127.206.96:8080/api/orders/create",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
            },
            body: JSON.stringify(orderData),
          }
        );

        const data = await response.json();

        if (response.ok) {
          setOrderCreated(true); // Mark the order as created
        } else {
          console.log("Error creating order");
        }
      } catch (error) {
        console.error("Error while creating the order:", error);
      }
    };

    createOrder();
  }, [
    orderCreated, // Dependency to avoid duplicate calls
    selectedCourts,
    selectedDate,
    sport,
    timeSlotMap,
    email,
    userId,
    totalAmount,
    advance,
    balance,
    paymentResponse,
    pgId,
  ]);

  return (
    <div>
      <NavbarComponent />

      <Container>
        <Row className="topRow center">
          <img id="successImg" src={success} alt="success" />
        </Row>
        <Row className="center midRow">
          <h2>Booking Confirmed!</h2>
          <p>We have received your payment and confirmed your booking.</p>
        </Row>
        <Row className="center endRow">
          <DetailComponent
            sport={sport}
            name={name}
            address={address}
            firstImage={firstImage}
            selectedCourts={selectedCourts}
            selectedDate={selectedDate}
            fromTime={fromTime}
            toTime={toTime}
            timeSlotMap={timeSlotMap}
            button1={true}
            button2={true}
            button3={false}
          />
        </Row>
      </Container>
    </div>
  );
};

export default Success;
