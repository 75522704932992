import React, { useEffect, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import '../../index.css';
import './Home.css';
import NavbarComponent from '../../Components/Navbar';
import SportsTimePicker from '../../Components/Search';
import SportsCard from '../../Components/CardComponent';

const Home = () => {
  const [sportsData, setSportsData] = useState([]); // State to store API data
  const [userLocation, setUserLocation] = useState(null); // State to store user location
  const [filteredData, setFilteredData] = useState([]); // Filtered data based on search query
  const [searchQuery, setSearchQuery] = useState(''); // Search query state

  // Fetch the user's geolocation (latitude, longitude)
  useEffect(() => {
    // Check if the browser supports geolocation
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ latitude, longitude });
        },
        (error) => {
          console.error("Error fetching geolocation: ", error);
          setUserLocation(null); // Fallback in case of error
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  // Fetch sports data from API
  useEffect(() => {
    // Check if sports data already exists in localStorage
    const storedData = localStorage.getItem('sportsData');
    if (storedData) {
      // If data exists in localStorage, use it
      setSportsData(JSON.parse(storedData));
      setFilteredData(JSON.parse(storedData)); // Initially set filtered data to all data

    } else {
      // Fetch data from API if not in localStorage
      const fetchSportsData = async () => {
        try {
          const response = await fetch('http://93.127.206.96:8080/api/pg/city/Mysuru');
          if (!response.ok) {
            throw new Error('Failed to fetch sports data');
          }
          const data = await response.json();
          
          // Store the fetched data in localStorage
          localStorage.setItem('sportsData', JSON.stringify(data));
          
          // Update state with fetched data
          setSportsData(data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchSportsData();
    }
  }, []);

  // Filter data whenever searchQuery changes
  useEffect(() => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    const filtered = sportsData.filter((sport) =>
      sport.name.toLowerCase().includes(lowerCaseQuery)
    );
    setFilteredData(filtered);
  }, [searchQuery, sportsData]);

  return (
    <>
      <NavbarComponent onSearchChange={setSearchQuery} />
      <Container fluid className="p-0">
        <Row className="custom-row">
          <div className="overlay"></div>
          <div className="content">
            <h1>Get Moving, Book Your Game Now!</h1>
            <p>
              From classics like badminton and cricket to the excitement of
              football, enjoy your favourite sports!
            </p>
            {/* Pass setSportsData as a prop to SportsTimePicker */}
            <SportsTimePicker onSearch={setSportsData} />
          </div>
        </Row>

        <Container className="p-5">
          <h1 style={{ fontSize: '20px', fontWeight: '500', lineHeight: '23px' }}>Recommended</h1>
          <Row className="card-grid">
            {filteredData.length > 0 ? (
              filteredData.map((sport) => (
                <SportsCard 
                  key={sport.id} 
                  sport={sport} 
                  userLocation={userLocation} 
                />
              ))
            ) : (
              <p>No results found.</p>
            )}
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default Home;
