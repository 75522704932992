import React, { useState } from 'react';
import { SlCalender } from 'react-icons/sl';
import { CiClock1, CiLocationArrow1 } from 'react-icons/ci';
import { GiTennisCourt } from 'react-icons/gi';
import '../BookingDetails/bookingDetails.css';
import './payment.css';
import { FiPrinter } from "react-icons/fi";
import { toast, ToastContainer } from 'react-toastify';

const BookingDetails = ({ 
  sport, 
  name, 
  address, 
  firstImage, 
  selectedDate, 
  fromTime, 
  toTime, 
  timeSlotMap, 
  selectedCourts,
  handlePayment,
  button1, 
  button2,
  button3,
  customStyle,
  showHrLine
}) => {
  const [location, setLocation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const totalAmount = selectedCourts.reduce((acc, court) => acc + court.cost, 0) + selectedCourts.length * 25;

  const getLocation = () => {
    if (!navigator.geolocation) {
      toast.error("Geolocation is not supported by your browser.");
      return;
    }

    setLoading(true);

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setLocation({ latitude, longitude });
        setLoading(false);
      },
      (error) => {
        toast.error("Failed to get location. Please try again.");
        console.error(error);
        setLoading(false);
      }
    );
  };

  const printInvoice = () => {
    setLoading2(true);
    toast.success('Printing invoice...');
    setLoading2(false);
  };


  return (
    
    <div className="right-section" style={{ width: '80%',margin:'auto',...customStyle }}>
      <div className="sport-summary">
        <div className="sport-selected">
          <div className="sport-text">
            <p>{sport}</p>
            <h4>{name}</h4>
            <h5>{address}</h5>
          </div>
          <img src={firstImage} width="100px" alt="Sport" />
        </div>

        <hr />

        <div className="booking-details">
          <h5>Booking Details</h5>
          <p>
            <SlCalender className="booking-icons" style={{ marginRight: '20px' }} />
            {selectedDate}
          </p>
          <p>
            <CiClock1 className="booking-icons" style={{ marginRight: '20px' }} />
            {fromTime} - {toTime}
          </p>
          <p>
            <GiTennisCourt className="booking-icons" style={{ marginRight: '20px' }} />
            {selectedCourts.map((court) => `Court ${court.court}`).join(', ')}
          </p>
        </div>

        <hr />

        <div className="price-summary">
          <h4>Price</h4>
          <div className="price-row">
            <p>Court:</p>
            <p>₹{selectedCourts.reduce((acc, court) => acc + court.cost, 0)}</p>
          </div>
          <div className="price-row">
            <p>Fee:</p>
            <p>₹{selectedCourts.length * 25 /* Fixed fee per court */}</p>
          </div>
          <div className="price-row">
            <p style={{ color: 'black', fontSize: '20px', fontWeight: '550', lineHeight: '20px' }}>Total:</p>
            <p id="totalAmt">₹{totalAmount}</p>
          </div>

          {showHrLine && <hr />} {/* Conditional horizontal line */}

          <div className="checkout-button-container">
            {button1 && (
              <button
                className="btn btn-primary checkout-button"
                onClick={getLocation}
                disabled={loading}
              >
                {loading ? (
                  <>
                    <CiLocationArrow1 style={{ marginRight: '8px' }} />
                    Fetching Location...
                  </>
                ) : (
                  <>
                    <CiLocationArrow1 style={{ marginRight: '8px' }} />
                    Get Location
                  </>
                )}
              </button>
            )}

            {button2 && (
              <button
                style={{
                  marginTop: '3%',
                  backgroundColor: '#EFF4FF',
                  color: 'var(--primary-color)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '8px',
                }}
                className="btn btn-primary checkout-button"
                onClick={printInvoice}
                disabled={loading2}
              >
                {loading2 ? (
                  <>
                    <FiPrinter />
                    Printing...
                  </>
                ) : (
                  <>
                    <FiPrinter />
                    Print Invoice
                  </>
                )}
              </button>
            )}

            {button3 && (
              <button
                style={{
                  marginTop: '3%',
                  backgroundColor: '#EFF4FF',
                  color: 'var(--primary-color)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '8px',
                }}
                className="btn btn-primary checkout-button"
                onClick={handlePayment}
                disabled={loading2}
              >
                {loading2 ? (
                  <>
                    <FiPrinter />
                    Retrying...
                  </>
                ) : (
                  <>
                    <FiPrinter />
                    Retry Payment
                  </>
                )}
              </button>
            )}
          </div>

          {location && (
            <div className="location-display">
              <p>Latitude: {location.latitude}</p>
              <p>Longitude: {location.longitude}</p>
            </div>
          )}
        </div>
        <ToastContainer/>
      </div>
    </div>
  );
};

export default BookingDetails;
