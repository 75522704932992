import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import './Login.css';
import Logo from '../../images/logo.svg';
import LoginForm from './LoginForm';
import { Col } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';

const Login = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [authToken, setAuthToken] = useState(localStorage.getItem('authToken')); // Track token in state

  // Check if the URL contains a token and set it in localStorage
  React.useEffect(() => {
    const pathParts = location.pathname.split('/');
    const tokenFromURL = pathParts[pathParts.length - 1];

    // If token exists in URL, and not already in localStorage, store it
    if (tokenFromURL && tokenFromURL !== 'login' && !authToken) {
      localStorage.setItem('authToken', tokenFromURL); // Set the token in localStorage
      setAuthToken(tokenFromURL); // Update the state to reflect the token
    }
  }, [location, authToken]);

  // Handle login form submission (Assuming LoginForm passes the token to parent)
  const handleLoginSuccess = (token) => {
    localStorage.setItem('authToken', token); // Store the token in localStorage
    setAuthToken(token); // Update the state
    navigate('/home'); // Redirect to home after successful login
  };

  return (
    <div className="login-bg">
      <Container fluid className="login-container h-50 p-0">
        {/* Left Side */}
        <Col>
          <div className="text-center p-4">
            <img className="image" src={Logo} alt="Sport" />
          </div>
        </Col>
        {/* Right Side - Form Section */}
        <Col className="Login-form-col">
          <LoginForm onLoginSuccess={handleLoginSuccess} />
        </Col>
      </Container>
    </div>
  );
};

export default Login;
