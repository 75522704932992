import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Container, Button, Nav, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import Logo from '../images/logo-navbar.png';
import locationIcon from '../images/location.svg';
import './Component.css';

function NavbarComponent({onSearchChange}) {
  const [location, setLocation] = useState('Fetching location...');
  const [token, setToken] = useState(null); // State to manage the authToken
  const [searchQuery, setSearchQuery] = useState(''); // State to manage the search input
  const navigate = useNavigate(); // Initialize the navigate function

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    onSearchChange(query); // Call the callback prop to update search query in Home
  };

  // Fetch the user's location using the Geolocation API
  const fetchLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;

          // Call OpenStreetMap's Nominatim API for reverse geocoding
          const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&addressdetails=1`;

          fetch(url)
            .then((response) => response.json())
            .then((data) => {
              const address = data.address;
              if (address) {
                const locationName = `${address.city || address.town || address.village}, ${address.state || ''}, ${address.country || ''}`;
                setLocation(locationName.trim());
              } else {
                setLocation('Unable to determine location');
              }
            })
            .catch((error) => {
              console.error('Error fetching location name:', error);
              setLocation('Error fetching location');
            });
        },
        (error) => {
          console.error('Geolocation error:', error);
          setLocation('Error fetching location');
        },
        {
          timeout: 20000,
          enableHighAccuracy: true,
        }
      );
    } else {
      setLocation('Geolocation not supported');
    }
  };

  // Fetch token from localStorage and update the state on component mount
  useEffect(() => {
    fetchLocation();
    const storedToken = sessionStorage.getItem('authToken');
    if (storedToken) {
      setToken(storedToken);
    }
  }, []);

  // Function to handle Login button click
  const handleLoginClick = () => {
    navigate('/Login'); // Navigate to the Login route
  };

  // Function to handle Sign Up button click
  const handleSignUpClick = () => {
    navigate('/login/signUp'); // Navigate to the Sign Up route
    
  };

  // Function to handle Logout button click
  const handleLogoutClick = () => {
    sessionStorage.removeItem('authToken'); // Remove token from localStorage
    sessionStorage.removeItem('email');
    sessionStorage.removeItem('userData');
    setToken(null); // Update the token state to null
    navigate('/'); // Redirect to the root (home) page
  };

  // Function to handle Bookings button click
  const handleBookingsClick = () => {
    navigate('/bookings'); // Navigate to the Bookings page
  };

  return (
    <Navbar expand="lg" style={{ backgroundColor: 'white', padding: '0.5% 12%' }} sticky="top">
      <Container fluid>
        {/* Logo */}
        <Navbar.Brand href="/" className="fw-bold">
          <img
            src={Logo}
            alt="Sport"
            style={{
              height: '50px',
              objectFit: 'contain',
              zIndex: 10,
              position: 'relative',
            }}
          />
        </Navbar.Brand>

        <Navbar.Toggle style={{ width: '10%' }} aria-controls="navbar-nav" />
        <Navbar.Collapse id="navbar-nav">
          <Nav className="ms-auto d-flex align-items-center">
            <div style={{ marginRight: '10px' }} className="d-flex align-items-center flex-nowrap">
              <img src={locationIcon} alt="location" />
              <span style={{ whiteSpace: 'nowrap', marginLeft: '4%' }}>{location}</span>
            </div>

            {/* Search Bar */}
            <Form.Control
              type="text"
              placeholder="Search..."
              style={{ width: '250px', margin: '0 15px' }}
              value={searchQuery}
              onChange={handleSearchChange} // Update search query
              />

            {/* Conditionally render buttons based on token value */}
            {token === null || token === 'Login' ? (
              // If token is null, show Sign Up and Login buttons
              <>
                <Button
                  variant="outline-dark"
                  className="me-2"
                  style={{ minWidth: '120px', marginLeft: '10px',  }}
                  onClick={handleSignUpClick}
                >
                  Sign Up
                </Button>
                <Button
                  variant="primary"
                  style={{ minWidth: '120px', marginLeft: '10px' }}
                  onClick={handleLoginClick}
                >
                  Login
                </Button>
              </>
            ) : (
              // If token exists, show Logout button
              <>
                <Button
                  variant="outline-danger"
                  style={{ minWidth: '120px', marginLeft: '10px' }}
                  onClick={handleLogoutClick} // Logout button click handler
                >
                  Logout
                </Button>
                <Button
                  variant="primary"
                  style={{ minWidth: '120px', marginLeft: '10px' }}
                  onClick={handleBookingsClick} // Bookings button click handler
                >
                  Bookings
                </Button>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarComponent;
