import React, { useState } from "react";
import { resetPasswordWithToken } from "../../Controllers/Auth_Controllers/AuthController"; 
import { useNavigate, useParams } from "react-router-dom"; // React Router v6
import "./Login.css"; // Reuse the same CSS for consistent design

const ResetPassword = () => {
  const { token } = useParams(); // Extract token from URL
  const [email, setEmail] = useState(""); // You might fetch the email based on the token
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();

  // Handle form submission
  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Basic validation
    if (!password || password !== confirmPassword) {
      setError("Passwords must match and cannot be empty");
      setLoading(false);
      return;
    }

    setError("");
    setSuccessMessage("");

    try {
      const response = await resetPasswordWithToken(token, email, password);
      if (response.success) {
        setSuccessMessage("Password reset successful. You can now log in.");
        setTimeout(() => navigate("/login"), 2000); // Redirect to login page after success
      } else {
        setError(response.message);
      }
    } catch (error) {
      console.error("Error:", error);
      setError("An unexpected error occurred");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-form">
      <h1>Reset Password</h1>

      {error && <p className="error-message">{error}</p>}
      {successMessage && <p className="success-message">{successMessage}</p>}

      <form onSubmit={handlePasswordReset}>
        <div className="input-field">
          <label>Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            required
            
          />
        </div>

        <div className="input-field">
          <label>New Password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter new password"
            required
          />
        </div>

        <div className="input-field">
          <label>Confirm Password</label>
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="Confirm new password"
            required
          />
        </div>

        <button type="submit" disabled={loading} className="login-button">
          {loading ? "Resetting..." : "Submit"}
        </button>
      </form>
    </div>
  );
};

export default ResetPassword;
