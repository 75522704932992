import React, { useEffect, useState } from 'react';
import NavbarComponent from '../../Components/Navbar';
import { Row, Container } from 'react-bootstrap';
import BookingCard from '../../Components/BookingsCard';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './bookingDetails.css';

const Bookingpage = () => {
  const [bookingData, setBookingData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);

  const userDataString = sessionStorage.getItem('userData');
  const authToken = sessionStorage.getItem('authToken');
  const userData = JSON.parse(userDataString);
  const userId = userData?.userId;
  const userMail = sessionStorage.getItem('email');

  const notify = (type, message) => {
    toast[type](message, {
      position: 'top-right',
      autoClose: 3000,
    });
  };

  useEffect(() => {
    if (!userId || !authToken) {
      setError('User ID or Auth Token not found in session storage');
      setLoading(false);
      return;
    }

    const fetchBookings = async () => {
      try {
        const response = await fetch(
          `http://93.127.206.96:8080/api/orders/user/${userId}?email=${userMail}&page=${currentPage}&size=${pageSize}`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${authToken}`,
              'Content-Type': 'application/json',
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setBookingData(data.content || []);
        } else {
          setError('Failed to fetch bookings');
        }
      } catch (err) {
        console.error('Error fetching bookings:', err);
        setError('An error occurred while fetching bookings');
      } finally {
        setLoading(false);
      }
    };

    fetchBookings();
  }, [userId, authToken, currentPage, pageSize]);

  const handleCancelBooking = async (booking) => {
    try {
      const response = await fetch(
        `http://93.127.206.96:8080/api/orders/cancel/${booking.id}`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.ok) {
        notify('success', 'Booking canceled successfully!');
        setBookingData((prevData) =>
          prevData.map((b) =>
            b.id === booking.id ? { ...b, status: 'Cancelled' } : b
          )
        );
      } else {
        const errorData = await response.json();
        console.error('Cancel booking failed:', errorData);
        notify('error', `Failed to cancel booking: ${errorData.errorMessage}`);
      }
    } catch (err) {
      console.error('Error while canceling booking:', err);
      notify('error', 'An error occurred while canceling the booking.');
    }
  };

  return (
    <div>
      <Row>
        <NavbarComponent />
        <Container>
          <div className="bookings-Container">
            <h1>Bookings</h1>
            <h3 className='subheading-booking-page'>
              Upcoming Bookings
              {bookingData.length > 0 && (
                <span style={{ fontSize: '18px', marginLeft: '10px', color: '#666' }}>
                  ({bookingData.filter((booking) => booking.bookingStatus === 'Confirmed').length})
                </span>
              )}
            </h3>
              <div className="booking-cards-container">
              {error ? (
                <p className="error-message">{error}</p>
              ) : loading ? (
                <p>Loading...</p>
              ) : bookingData.length > 0 ? (
                bookingData.map((booking) => (
                  <BookingCard
                    pgId={booking.pgId}
                    key={booking.id}
                    heading={booking.pgName}
                    date={new Date(booking.bookingDate).toLocaleDateString()}
                    address={booking.address}
                    fromTime={booking.startTime}
                    toTime={booking.endTime}
                    sport={booking.sport}
                    courts={booking.court.split(',')}
                    booking={booking}
                    onCancel={handleCancelBooking}
                    showHrLine={true}
                    />
                ))
              ) : (
                <p>No bookings available</p>
              )}
            </div>
            
          </div>
        </Container>
      </Row>
      <ToastContainer />
    </div>
  );
};

export default Bookingpage;
