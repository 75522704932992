import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from './Pages/Auth/Login';
import './App.css';
import ForgotPasswordPage from './Pages/Auth/ForgotPassword';
import ResetPasswordPage from './Pages/Auth/ResetPasswordPage';
import Home from './Pages/Home/Home';
import 'bootstrap/dist/css/bootstrap.min.css';
import CourtDetails from './Pages/Home/Court';
import BookingForm from './Pages/BookingDetails/BookingDetails';
import Success from './Pages/Payment/Success';
import Failure from './Pages/Payment/Failure';
import Bookingpage from './Pages/BookingDetails/Bookingpage';

function App() {
  const [locationName, setLocationName] = useState('Fetching location...');
  const [location, setLocation] = useState(null);  // Store latitude and longitude
  const [loading, setLoading] = useState(true);  // Loading state
  const [mode,setMode] = useState("login");

  // Function to fetch user's location
  const fetchLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude }); // Store coordinates
          setLoading(false); // Set loading to false once location is fetched

          // Call OpenStreetMap's Nominatim API for reverse geocoding
          const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&addressdetails=1`;
          fetch(url)
            .then((response) => response.json())
            .then((data) => {
              const address = data.address;
              if (address) {
                const locationName = `${address.city || address.town || address.village}, ${address.state || ''}, ${address.country || ''}`;
                setLocationName(locationName.trim());
              } else {
                setLocationName('Unable to determine location');
              }
            })
            .catch((error) => {
              console.error('Error fetching location name:', error);
              setLocationName('Error fetching location');
            });
        },
        (error) => {
          console.error('Geolocation error:', error);
          setLocation(null);  // If there's an error, set location to null
          setLoading(false);
        },
        {
          timeout: 20000,
          enableHighAccuracy: true,
        }
      );
    } else {
      setLocation(null); // Geolocation not supported
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLocation(); // Fetch location when the component mounts
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route path="/Login" element={<Login />} />
        <Route path="/login/signup" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/forgot-password/:token" element={<ResetPasswordPage />} />
        <Route
          path="/"
          element={<Home location={locationName} latitude={location?.latitude} longitude={location?.longitude} loading={loading} />}
        />
        <Route path="/court" element={<CourtDetails />} />
        <Route path="/booking-form" element={<BookingForm />} />
        <Route path="/success" element={<Success/>}/>
        <Route path="/failure" element={<Failure/>}/>
        <Route path='/bookings' element={<Bookingpage/>}/>
      </Routes>
    </div>
  );
}

export default App;
